<template>
	<div class="archives_score-pages">
        <tabbed-page
            :tabList="tabList"
            @changeTabs="changeTabs"
        >
        </tabbed-page>
		<!-- 动态路由 -->
		<div class="route-content">
			<component :is="comps[idx]"></component>
		</div>
	</div>
</template>

<script>
	import { DialogWrapper } from 'common-local';
	// 综评成绩
    import TabbedPage from '../Sub/TabbedPage/index.vue'
	import SynthesisBox from './Sub/SynthesisBox.vue';
    import { tabPageHasAuth } from '@/libs/tabbedPageAuth.js';
	// 平均分
	export default {
		name: 'ArchivesScore',
		components: {
            TabbedPage,
			DialogWrapper,
			SynthesisBox,
			SyaverageBox:()=>import (/* webpackChunkName:'SyaverageBox' */"./Sub/SyaverageBox.vue"),
		},
		data() {
			return {
                tabList: [
                    {
                        id: 0,
                        title: '综评成绩',
                        com: 'SynthesisBox',
                        vHasPermi: ['programme:score'],
                    },
                    {
                        id: 1,
                        title: '平均分',
                        com: 'SyaverageBox',
                        vHasPermi: ['programme:average'],
                    },
                ],
				activeName: '',
				idx: 0,
				routerReturn: undefined,
				comps: ['SynthesisBox', 'SyaverageBox']
			}
		},
		created() {
            this.init();
		},
		methods: {
            init () {
                this.tabList = tabPageHasAuth(this.tabList);
                this.comps = this.tabList.map(item => item.com);
                console.log(this.comps, this.tabList, 'this.tabList')
            },
			/**
			 * @Description: tab导航切换
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 21:13:22
			 */
			handleClick(tab, event) {
				switch(tab.name) {
					case 'synthesis':
						// 体育项目维护
						this.idx = 0;
						break;
					case 'average':
						// 体育选修课维护
						this.idx = 1;
						break;
				}
			},
            changeTabs (data) {
                this.tabList.forEach((item, index) => {
                    if (item.id === data.id) {
                        this.$set(item, 'sel', true)
                        this.idx = index;
                    } else {
                        this.$set(item, 'sel', false)
                    }
                })
            },
		}
	}
</script>

<style scoped lang="scss">
	.archives_score-pages .el-tabs {
		background-color: #fff;
	}

	.archives_score-pages /deep/ .el-tabs__header {
		margin-bottom: 0;
	}

	.archives_score-pages /deep/ .el-tabs__nav-wrap::after {
		height: 1px;
		background-color: #eff1f8;
	}

	.archives_score-pages /deep/ .el-tabs__nav-scroll {
		padding-left: 40px;
	}

	.archives_score-pages /deep/ .el-tabs__item.is-active {
		color: #3c7fff;
		font-size: 14px;
		font-weight: 600;
	}

	.archives_score-pages /deep/ .el-tabs__item {
		color: #2b2f33;
		font-size: 14px;
	}

	.archives_score-pages /deep/ .el-tabs__item:hover {
		color: #3c7fff;
	}

	.archives_score-pages /deep/ .el-tabs__active-bar {
		background-color: #3c7fff;
	}
</style>
