<template>
	<div class="archives_programme-pages">
		<div v-if="!errorShow" class="right-panel">
			<loading :loadingShow="loadingShow"></loading>
			<div class="filter-wrap">
				<expand-filter
					:formData="formData"
					marginBottom="0"
					@clickBtn="clickBtn"
					@changeBtnFormType="changeBtnFormType"
					:closeWidth="360">
				</expand-filter>
				<div class="button-line" v-hasPermi="['programme:add']"></div>
				<div  style="padding-bottom: 10px;">
					<el-button v-hasPermi="['programme:add']" type="primary" @click="add()">添加</el-button>
				</div>
			</div>

			<div class="table-paging">
				<table-data
					v-loading="loadingTable"
					ref="table" id="table"
					:config="table_config"
					:tableData="tableData"
					@linkClick="handlerTableLinkClick">
					<!-- <template #name="{ data }">
						<span style="color: #3c7fff; cursor: pointer" @click="detailMeeting(data)">
							{{ data.name }}
						</span>
					</template> -->

					<template #isArchive="{ data }">
						<el-dropdown @command="handleReturnCom" v-has-permi="['programme:categorize']">
							<span class="el-dropdown-link">
								{{ data.isArchive == 0 ? '展示' : '归档' }}<i class="el-icon-arrow-down el-icon--right"></i>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item :command="`${data.id}-${data.isArchive}`" :disabled="data.isArchive == 0 ? true : false">
									展示
								</el-dropdown-item>
								<el-dropdown-item :command="`${data.id}-${data.isArchive}`" :disabled="data.isArchive == 1 ? true : false">
									归档
								</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</template>

					<template v-slot:operation="slotData">
						<el-button
							v-has-permi="['programme:updateLevel']"
							type="text" size="mini"
							@click="updateGrade(slotData.data)"
						>更新等级</el-button>
						<el-button
							v-if="slotData.data.scoreStatus === '0'"
							v-has-permi="['programme:create']"
							type="text" size="mini"
							@click="createGrade(slotData.data)"
						>生成成绩</el-button>
						<el-button
							v-if="slotData.data.scoreStatus === '0'"
							v-hasPermi="['programme:edit']"
							type="text" size="mini"
							@click="editOpenMeeting(slotData.data)"
						>编辑</el-button>
						<el-button
							v-has-permi="['programme:delete']"
							type="text" size="mini"
							@click="deleteOpen(slotData.data)"
						>删除</el-button>
					</template>
				</table-data>
				<pagination :total="total" :page.sync="listQuery.pageNum" :limit.sync="listQuery.pageRow" @pagination="getTableList(true)" />
			</div>
			<!-- 新建弹窗 -->
			<div class="dialog-add">
				<dialog-wrapper :dialog-obj="dialogAddObj" @handleClose="handleAddClose">
					<programme-add @change="addCloseDialog" :dialogAddObj="dialogAddObj"></programme-add>
				</dialog-wrapper>
			</div>
			<!-- 编辑弹窗 -->
			<div class="dialog-edit">
				<dialog-wrapper :dialog-obj="dialogEditObj" @handleClose="handleEditClose">
					<programme-edit @change="editCloseDialog" :dialogEditObj="dialogEditObj"></programme-edit>
				</dialog-wrapper>
			</div>
			<!-- 详情弹窗 -->
			<div class="dialog-edit">
				<dialog-wrapper :dialog-obj="dialogDetailObj" @handleClose="handleDetailClose">
					<programme-detail @change="detailCloseDialog" :dialogDetailObj="dialogDetailObj"></programme-detail>
				</dialog-wrapper>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		// 业务组件
		FilterData,
		DialogWrapper,
		Pagination,
		// 功能组件
		Error,
		Loading,
		// 工具函数
		throttle,
	} from 'common-local';
	import TableData from "@/components/scrollWrapper/Sub/TableData"
	import {
		mapState
	} from 'vuex';
	import {
		hasPermission
	} from '@/libs/utils';
	import ExpandFilter from '../Sub/ExpandFilter';
	// 方案配置添加
	import ProgrammeAdd from './Sub/ProgrammeAdd.vue';
	// 方案配置编辑
	import ProgrammeEdit from './Sub/ProgrammeEdit.vue';
	// 方案配置详情
	import ProgrammeDetail from './Sub/ProgrammeDetail.vue';

	export default {
		name: 'ArchivesProgramme',
		components: {
			FilterData,
			TableData,
			Pagination,
			DialogWrapper,
			// 功能组件
			Error,
			Loading,
			ExpandFilter,
			ProgrammeAdd,
			ProgrammeEdit,
			ProgrammeDetail
		},
		data() {
			return {
				errorShow: false,
				loadingShow: false,
				loadingTable: false,
				dialogVisible: false,
				// 头部筛选
				formData: {
					data: [{
						type: 'select',
						label: '',
						value: '',
						placeholder: '选择状态',
						key: 'scoreStatus',
						list: [{
							value: '0',
							label: '未生成'
						},{
							value: '1',
							label: '已生成'
						}],
					}, {
						type: 'input',
						label: '',
						value: '',
						placeholder: '方案名称',
						key: 'name'
					}],
					btnList: [
						{
							type: 'primary',
							text: '查询',
							fn: 'primary',
							auth: ['programme:list']
						},
						// {
						// 	type: 'enquiry',
						// 	text: '重置',
						// 	fn: 'reset'
						// }
					],
					btnFormType: true
				},
				// 表格配置
				table_config: {
					thead: [{
						label: "方案名称",
						prop: "name",
						type: "link",
						clickAuth: ["programme:detail"],
						align: "center",
						labelWidth: "350"
					}, {
						label: "关联考试",
						prop: "examNames",
						type: "popoverText",
						align: "center",
						labelWidth: "450"
					}, {
						label: "成绩状态",
						prop: "scoreStatus",
						type: "function",
						align: "center",
						callBack(row) {
							switch (Number(row.scoreStatus)) {
								case 0:
									return `<span style='display:inline-block; width:6px; height:6px; border-radius: 50%; background-color: #ee3737; margin-right:5px;margin-bottom: 2px;'></span><span>未生成</span>`;
								case 1:
									return `<span style='display:inline-block; width:6px; height:6px; border-radius: 50%; background-color: #64cf6d; margin-right:5px;margin-bottom: 2px;'></span><span>已生成</span>`;
							}
						}
					}, {
						label: "方案归档",
						labelDescription: "注：方案归档后在综评成绩管理中不显示对应的方案，平均分列表中不展示已经归档的方案",
						type: "slot",
						slotName: "isArchive",
						align: "center"
					}, {
						label: "操作",
						type: "slot",
						labelWidth: "200",
						slotName: "operation",
						align: "center",
						renderHeader: null,
						className: "text-spacing-reduction"
					}],
					check: false,
					height: ''
				},
				backgroundHeader: '',
				headerColor: '#595959',
				// 分页总数
				total: 0,
				// 表格数据
				tableData: [],
				// 分页和查询条件
				listQuery: {
					pageNum: 1,
					pageRow: 20,
					scoreStatus: '',
					name: ''
				},
				// 添加
				dialogAddObj: {
					title: '方案添加',
					dialogVisible: false,
					width: '640px',
					keyId: ''
				},
				// 编辑
				dialogEditObj: {
					title: '方案编辑',
					dialogVisible: false,
					id: '',
					keyId: '',
					width: '640px'
				},
				// 详情
				dialogDetailObj: {
					title: '方案详情',
					dialogVisible: false,
					id: '',
					keyId: '',
					width: '640px'
				},
				programmeEditBtn: false,
				programmeDeleteBtn: false,
				programmeCreateBtn: false,
				programmeUpdateLevelBtn: false
			}
		},
		computed: {
			...mapState({
				permissions: (state) => state.permissions
			})
		},
		created() {
			this.init();
		},
		mounted() {
			this.table_config.height = document.body.clientHeight - this.$refs.table.$el.offsetTop - 137;
			let permissionsList = this.$store.state.permissions
			for(let j = 0; j < permissionsList.length; j++) {
				switch (permissionsList[j]) {
					case 'programme:edit':
						this.programmeEditBtn = true;
						break;
					case 'programme:delete':
						this.programmeDeleteBtn = true;
						break;
					case 'programme:create':
						this.programmeCreateBtn = true;
						break;
					case 'programme:updateLevel':
						this.programmeUpdateLevelBtn = true;
						break;
				}


			}
		},
		methods: {
			/**
			 * @Description: 加载
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-18 17:02:13
			 */
			init() {
				this.getTableList(1);
			},
			/**
			 * @Description: 展开 收起
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-18 18:36:58
			 */
			changeBtnFormType() {
				this.formData.btnFormType = !this.formData.btnFormType;
				this.$nextTick(() => {
					this.table_config.height = document.body.clientHeight - this.$refs.table.$el.offsetTop - 62;
				});
			},
			/**
			 * @Description: 操作按钮
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-18 18:40:01
			 */
			clickBtn(ev) {
				switch (ev.item.fn) {
					case 'primary': // 查询
						this.formData.data.forEach((item) => {
							this.listQuery[item.key] = item.value;
						})
						this.getTableList(1);
						break;
					case 'reset': // 重置
						this.formData.data.forEach((item) => {
							item.value = '';
							this.listQuery[item.key] = ''
						})
						this.getTableList(1);
						break;
					default:
						break
				}
			},
			/**
			 * @Description: 获取列表数据
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-18 18:42:39
			 */
			getTableList(isPaging) {
				if (isPaging === 1) {
					this.listQuery.pageNum = 1;
				}
				this.loadingTable = true;
				this._fet('/school/schoolCerProgramme/list', this.listQuery)
					.then((res) => {
						if (res.data.code === "200") {
							this.tableData = res.data.data.list;
							this.total = res.data.data.totalCount;
						} else if (res.data && res.data.msg) {
							this.$message.error(res.data.msg);
						}
						this.loadingTable = false;
					});
			},
			/**
			 * @Description: 切换归档状态
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-18 18:43:17
			 */
			handleReturnCom(command) {
				let valueList = command.split('-');
				let text = valueList[1] == 0 ? '归档': '展示'
				let isArchive = valueList[1] == 0 ? '1': '0'
				this.$confirm('确定'+ text +'吗?', text + '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this._fet('/school/schoolCerProgramme/updateArchiveInfo', {
						id: valueList[0],
						isArchive: isArchive
					}).then((res) => {
						if (res.data.code === '200') {
							this.$message.success("操作成功！");
							this.getTableList();
						} else if (res.data && res.data.msg) {
							this.$message.error(res.data.msg);
						}
					});
				}).catch(() => {

				})
			},
			/**
			 * @Description: 归档状态查看说明
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-18 18:43:17
			 */
			isTipsShow() {
				console.log('鼠标悬停归档状态查看说明')
			},
			/**
			 * @Description: 归档状态查看说明隐藏
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-18 18:43:17
			 */
			isTipsHide() {
				console.log('isTipsShow归档状态查看说明隐藏')
			},
			/**
			 * @Description: 新建弹窗
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-18 18:43:17
			 */
			add() {
				this.dialogAddObj.keyId = Math.random() + '';
				this.dialogAddObj.dialogVisible = true;
			},
			/**
			 * @Description: 新建弹窗关闭监听
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-18 18:43:17
			 */
			addCloseDialog(data){
				if(data === 'YES') {
					this.getTableList(1);
				}
				this.dialogAddObj.dialogVisible = false;
			},
			/**
			 * @Description: 新建弹窗关闭监听
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-18 18:43:17
			 */
			handleAddClose() {
				this.dialogAddObj.dialogVisible = false;
			},
			/**
			 * @Description: 详情弹窗关闭监听
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-18 18:43:17
			 */
			detailCloseDialog(data){
				this.dialogDetailObj.dialogVisible = false;
			},
			/**
			 * @Description: 详情弹窗关闭监听
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-18 18:43:17
			 */
			handleDetailClose(){
				this.dialogDetailObj.dialogVisible = false;
			},
			/**
			 * @Description: 表格link点击事件
			 * @DoWhat: [无]
			 * @UseScenarios: [无]
			 * @Attention: [无]
			 * @Author: WGS
			 * @Date: 2023-08-16 11:39:12
			 */
			handlerTableLinkClick(rowData, tableItem) {
				if (tableItem.prop === 'name') {
					this.detailMeeting(rowData)
				}
			},
			/**
			 * @Description: 详情
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-18 18:45:03
			 */
			detailMeeting(dataItem) {
				// console.log('详情 ', dataItem)
				this.dialogDetailObj.keyId = Math.random() + '';
				this.dialogDetailObj.id = dataItem.id;
				this.dialogDetailObj.dialogVisible = true;
			},
			/**
			 * @Description: 编辑弹窗关闭监听
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-18 18:43:17
			 */
			editCloseDialog(data){
				if(data === 'YES') {
					this.getTableList();
				}
				this.dialogEditObj.dialogVisible = false;
			},
			/**
			 * @Description: 编辑弹窗关闭监听
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-18 18:43:17
			 */
			handleEditClose() {
				this.dialogEditObj.dialogVisible = false;
			},
			/**
			 * @Description: 编辑
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-18 18:51:19
			 */
			editOpenMeeting(dataItem) {
				// console.log('编辑 ', dataItem)
				this.dialogEditObj.keyId = Math.random() + '';
				this.dialogEditObj.id = dataItem.id;
				this.dialogEditObj.dialogVisible = true;
			},
			/**
			 * @Description: 删除
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-18 18:51:19
			 */
			deleteOpen(dataItem) {
				this.$confirm('删除后不能恢复，确定要删除吗?', '删除提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this._fet('/school/schoolCerProgramme/delete', {
						id: dataItem.id
					}).then((res) => {
						if (res.data.code === '200') {
							this.$message.success("删除成功！");
							this.getTableList();
						} else if (res.data && res.data.msg) {
							this.$message.error(res.data.msg);
						}
					});
				}).catch(() => {

				})
			},
			/**
			 * @Description: 生成成绩
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-18 18:55:22
			 */
			createGrade(dataItem) {
				this.$confirm('生成综评成绩后不能修改方案，您确定生成本方案的综评成绩吗?', '生成成绩提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this._fet('/school/schoolCerProgramme/createScoreInfo', {
						id: dataItem.id
					}).then((res) => {
						if (res.data.code === '200') {
							this.$message.success("操作成功！");
							this.getTableList();
						} else if (res.data && res.data.msg) {
							this.$message.error(res.data.msg);
						}
					});
				}).catch(() => {

				})
			},
			/**
			 * @Description: 更新等级
			 * @DoWhat:[无]
			 * @UseScenarios:[无]
			 * @Attention:[无]
			 * @Author: 羡国柱
			 * @Date: 2023-07-20 11:38:50
			 */
			updateGrade(dataItem) {
				this.$confirm('更新后不能恢复，确定要更新吗?', '更新提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this._fet('/school/schoolCerProgramme/updateLevel', {
						id: dataItem.id
					}).then((res) => {
						if (res.data.code === '200') {
							this.$message.success("操作成功！");
							this.getTableList();
						} else if (res.data && res.data.msg) {
							this.$message.error(res.data.msg);
						}
					});
				}).catch(() => {

				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.archives_programme-pages {
        padding-right: 10px;
    }

	.archives_programme-pages .filter-wrap {
		display: flex;
		text-align: center;
		align-items: flex-start;
		background-color: #fff;
		padding: 10px 10px 0;
		margin-bottom: 10px;
        margin-top: 10px;
		border-radius: 4px;
		overflow: hidden;
	}

	.archives_programme-pages .filter-wrap .expand-filter {
		padding: 0;
	}

	.archives_programme-more-button {
		color: #666;
		font-size: 14px;
		margin-left: 13px;
		font-family: Microsoft YaHei;
	}

	.archives_programme-more-button:hover {
		color: #3c7fff;
	}

	.archives_programme-more-btn .table-operation-btn-normal {
		color: #2b2f33 !important;
	}

	.archives_programme-more-btn .table-operation-btn-delete {
		color: #e63a2e !important;
	}

	.archives_programme-more-btn .archives_programme-more-btn-li:hover {
		background-color: #dae6f2;
	}

	.archives_programme-more-btn .archives_programme-more-btn-li-d:hover {
		background-color: #f5dedc;
	}
</style>
