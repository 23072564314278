<!--
 * @Author: 羡国柱
 * @Date: 2023-07-18 14:58:13
 * @LastEditors: 邵澳 1398037353@qq.com
 * @LastEditTime: 2023-08-16 17:27:57
 * @FilePath: \src\components\scrollWrapper\SEduArchives\Level.vue
 * @Description: 智慧教学 > 综评档案 > 等级设置
-->
<template>
	<div class="archives_level-pages">
		<div class="level-wraper">
			<div class="level-wraper-rows level-wraper-project">
				<div style="display: flex;align-items: center;">
					<div class="level-wraper-row-title level-wraper-row-title-lt">
						<span class="level-wraper-row-title-icon"></span>
						<span class="level-wraper-row-title-text">项目等级设置</span>
						
						<el-tooltip class="item" effect="dark" placement="right-start">
							<div slot="content">
								<p>1、选中的合格线以下设置为不合格；</p>
								<p>2、大于等于的分值规则从上到下依次降低；</p>
								<p>3、如果没有设置等级，成绩等级显示为空。</p>
							</div>
							<i class="el-icon-question"></i>
						</el-tooltip>
					</div>
					<span style="font-size: 14px;color: #fc4444;margin-left: 8px;">更改等级后，请手动更新方案等级</span>
				</div>
				<level-project></level-project>
			</div>
			
			<div class="level-wraper-rows level-wraper-total_score" style="margin-left: 50px;">
				<div class="level-wraper-row-title level-wraper-row-title-rt">
					<span class="level-wraper-row-title-icon"></span>
					<span class="level-wraper-row-title-text">总分等级设置</span>
					<el-tooltip class="item" effect="dark" 
						content="总分等级设置应用于每个方案的总分和平均分的等级展示和统计" placement="right-start">
						<i class="el-icon-question"></i>
					</el-tooltip>
				</div>
				<level-total-score></level-total-score>
			</div>
		</div>
	</div>
</template>

<script>
	
	import { DialogWrapper } from 'common-local'
	import { mapState } from 'vuex';
	
	// 项目等级设置
	import LevelProject from './Sub/LevelProject.vue';
	// 总分数等级设置
	import LevelTotalScore from './Sub/LevelTotalScore.vue';
	
	export default {
		name: 'ArchivesLevel',
		components: {
			DialogWrapper,
			LevelProject,
			LevelTotalScore
		},
		data() {
			return {
				isControl: null,
				isControled: null
			}
		},
		created() {
			
		},
		mounted() {
			this.$nextTick(() => {
				setTimeout(() => {
					document.getElementsByClassName('archives_level-pages')[0].style.height = (document.body.clientHeight - 132) + 'px';
				}, 100);
			});	
		},
		methods: {
			
		}
	}
	
</script>

<style scoped lang="scss">
	.archives_level-pages {
		margin: 10px 10px 10px 0;
		background-color: #fff;
	}
	
	.archives_level-pages .level-wraper {
		display: flex;
		/* justify-content: space-between; */
		padding-top: 38px;
		padding-left: 36px;
		padding-right: 36px;
		box-sizing: border-box;
	}
	
	.archives_level-pages .level-wraper-row-title {
		display: flex;
		align-items: center;
		position: relative;
	}
	
	.archives_level-pages .tips-warp {
		overflow: visible !important;
		position: absolute;
		top: 0;
		left: 138px;
		width: 234px;
		color: #fff;
		font-size: 12px;
		border-radius: 4px;
		background-color: rgba(48, 49, 51, 1);
	}
	
	.level-wraper-row-title-rt .tips-warp {
		overflow: visible !important;
		position: absolute;
		top: 0;
		left: 156px;
		border-radius: 4px;
		color: #fff;
		font-size: 12px;
		background-color: rgba(48, 49, 51, 1);
	}
	
	.tips-warp .tips-warp-arrows {
		position: absolute;
		top: 1px;
		left: -13px;
	}
	
	.tips-warp .tips-warp-arrows /deep/ .el-icon-caret-left {
		color: #303133;
		font-size: 20px;
	}
	
	.archives_level-pages .level-wraper-row-title-icon {
		width: 3px;
		height: 13px;
		background-color: #3c7fff;
		border-radius: 1.5px;
	}
	
	.archives_level-pages .level-wraper-row-title-text {
		color: #333;
		font-size: 16px;
		font-weight: 600;
		margin-left: 6px;
		margin-right: 8px;
	}
	
	.archives_level-pages /deep/ .el-icon-question {
		font-size: 14px;
		cursor: pointer;
		color: #666;
		cursor: pointer;
	}
</style>